.container__header{
  background: #000;
  height: 100%;
}

.container__header--logo{
  text-align: center;
  margin: 0;
  color: #FFF;
  padding: 15px 0;
}

.container--list{
  display: block;
}

.container--list--card{
  padding: 15px 15px;
  display: flex; 
}

.container--button__whatsapp{
  border: none;
  text-decoration: none;
  background: #28a745;
  align-content: center;
  padding: 0 5px;
}

.container--button__location{
  border: none;
  text-decoration: none;
  background: #1E90FF;
  align-content: center;
  padding: 0 5px;
}


.container--list__buttonconfirm{
  width: 100%;
  border: none;
  background: #000;
  color: #FFF;
  padding: 10px 0;
  font-weight: bold;
  font-size: 1rem;
}

.container--list__buttondeny{
  width: 100%;
  border: none;
  background: #dc3545;
  color: #FFF;
  padding: 10px 0;
  font-weight: bold;
  font-size: 1rem;
}

.container--list__button{
  
}
